import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { toast } from "react-toastify";
import { ConfirmLoad } from "../components/Settings/confirmLoad";
import { resetCCXT } from "../utils/loginCCXT";
import { useCSSContext } from "./cssContext";
import {
  fetchAccounts,
  fetchCurrentUser,
  inputAccounts,
  inputCurrentUser,
  inputLoadedSettings,
} from "../utils/localstorage";
import {
  checkBinanceKey,
  checkDeribitKey,
  decryptKeys,
  saveKeys,
} from "../utils/encryptDecrypt";
import axios from "axios";
import { useUserContext } from "./userContext";

interface SettingsContextProps {
  isTestKeysBinance: boolean;
  isTestKeysDeribit: boolean;
  fields: any;
  openModal: boolean;
  dataKeys: any;
  openPwd: boolean;
  typeLoad: boolean;
  saveAlert: any;
  apiTypeB: boolean;
  apiTypeD: boolean;
  currentUser: string;
  lockTime: number;
  binanceCheckState: boolean;
  deribitCheckState: boolean;
  lockPwdToggle: boolean;
  mockAPI: boolean;
  handleResetEncryption: () => Promise<string>;
  setMockAPI: (value: boolean) => void;
  handleLockNow: () => void;
  setLockPwd: (value: boolean) => void;
  setLockTime: (value: number) => void;
  setCurrentUser: (value: string) => void;
  setAPITypeB: (value: boolean) => void;
  setAPITypeD: (value: boolean) => void;
  setSaveAlert: (value: any) => void;
  setTypeLoad: (value: boolean) => void;
  setOpenPwd: (value: boolean) => void;
  setModal: (value: boolean) => void;
  handleField: (name: string, val: any, type: string) => Promise<void>;
  handleReset: (type: string) => Promise<void>;
  handleSave: (e: string, d?: any) => Promise<void>;
  handleSet: (apiKeys: any) => Promise<void>;
  handleCheckDeribit: (e: any, b: string) => Promise<string | void>;
  handleCheckBinance: (e: any, b: string) => Promise<string | void>;
  handleTestNetBinance: (e: boolean) => void;
  handleTestNetDeribit: (e: boolean) => void;
  handleDeleteUser: () => Promise<void>;
}

type EncryptedObject = {
  username: string;
  password: string;
  binancePublic: string;
  deribitSecret: string;
  binanceSecret: string;
  deribitPublic: string;
  salt: string;
  key1: string;
  key2: string;
  newKey2: string;
  binancePublic_specific: any;
  binanceSecret_specific: any;
  deribitPublic_specific: any;
  deribitSecret_specific: any;
  [key: string]: any;
};

type FieldsObject = {
  [key: string]: any;
  proxyType: string;
};

const SettingsContext = createContext<SettingsContextProps | undefined>(
  undefined
);
let count = 0;

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const { darkMode, setDarkMode } = useCSSContext();
  const {
    noAcc,
    mockAPI,
    path,
    isLogin,
    loggedinUser,
    setMockAPI,
    saveSettingsToAuth,
    setLoggedinUser,
  } = useUserContext();
  const [fields, setFields] = useState<any>({ proxyType: "http://" });
  const [oldFields, setOldFields] = useState<FieldsObject>({
    proxyType: "http://",
  });
  const [binanceCheckState, setBinanceCheckState] = useState(false);
  const [deribitCheckState, setDeribitCheckState] = useState(false);
  const [openModal, setModal] = useState(false);
  const [dataKeys, setDataKeys] = useState<any>(null);
  const [openPwd, setOpenPwd] = useState(false);
  const [typeLoad, setTypeLoad] = useState(false);
  const [saveAlert, setSaveAlert] = useState<any>(null);
  const [isTestKeysBinance, setIsTestKeysBinance] = useState(true);
  const [isTestKeysDeribit, setIsTestKeysDeribit] = useState(true);
  const [apiTypeB, setAPITypeB] = useState(false);
  const [apiTypeD, setAPITypeD] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [lockTime, setLockTime] = useState<number>(
    +process.env.NEXT_PUBLIC_LOCKSCREEN!
  );
  const [lockPwdToggle, setLockPwd] = useState(false);

  useEffect(() => {
    if (path !== "analytics") {
      checkAccounts();
    }
    if (isLogin && noAcc && mockAPI && path === "risk") {
      handleSave("initial");
    }
  }, [noAcc, mockAPI, isLogin]);

  const handleField = async (name: string, val: any, type: string) => {
    const obj: FieldsObject = fields;

    if (type === "specific") {
      obj[name] = val;
      setFields(obj);
      setOldFields(obj);
    } else {
      const newObj = { ...fields, [name]: val };
      setFields(newObj);
    }
  };

  const handleSave = async (type: string, d?: any) => {
    if (type === "initial") {
      const derPublic = process.env.NEXT_PUBLIC_TEST_DERIBIT_PUBLIC!;
      const derSecret = process.env.NEXT_PUBLIC_TEST_DERIBIT_SECRET!;
      resetCCXTNow({ derPublic, derSecret }, false, false, "initial");
      return;
    }

    if (type === "localstorage") {
      fields.apiTypeB = apiTypeB;
      fields.apiTypeD = apiTypeD;
      fields.username = loggedinUser?.sub;

      const encryptedData: EncryptedObject = await saveKeys(fields);
      const metadata = loggedinUser?.metadata?.user_metadata;
      let isSecretAvailable = false;

      for (const item in encryptedData) {
        if (
          item?.toLowerCase().includes("secret") &&
          item !== "secretAvailable"
        ) {
          isSecretAvailable = true;
          break;
        }
      }

      encryptedData.secretAvailable = isSecretAvailable;
      encryptedData.proxy = fields.proxy;
      encryptedData.apiTypeB = apiTypeB;
      encryptedData.apiTypeD = apiTypeD;
      encryptedData.darkMode = darkMode;
      encryptedData.isTestKeysBinance = isTestKeysBinance;
      encryptedData.isTestKeysDeribit = isTestKeysDeribit;
      encryptedData.lockscreen = {
        time: lockTime,
        pwdToggle: lockPwdToggle,
      };

      const currentUserLS: any = await fetchCurrentUser();
      if (!currentUserLS?.trading) {
        await inputCurrentUser(metadata?.sub, "trading");
      }

      handleField("lockTime", lockTime, "");
      setDataKeys(encryptedData);
      saveKeysToAuthAndLS(encryptedData);

      await reloadCCXT(encryptedData, "save");
      await renderNewFields();
    } else if (type === "load") {
      d = { ...d, proxy: fields.proxy };
      d = { ...d, isTestKeysBinance: isTestKeysBinance };
      d = { ...d, isTestKeysDeribit: isTestKeysDeribit };
      d = { ...d, apiTypeB };
      d = { ...d, apiTypeD };

      setDataKeys(d);
      await reloadCCXT(d);
      await renderNewFields();
      toast.success("Successfully saved your settings in local storage");
    }
  };

  const handleResetEncryption = async () => {
    const accounts = await fetchAccounts();
    const removeFromAccounts = accounts.filter(
      (item) => item.username !== loggedinUser?.sub
    );

    await inputAccounts(removeFromAccounts);
    await saveSettingsToAuth(false, "secretAvailable");

    const updatedLoggedinUser = await saveSettingsToAuth(null, "password");

    handleSet(updatedLoggedinUser.user_metadata);
    toast.success("Successfully reset encryption key.");
    return "success";
  };

  const renderNewFields = async () => {
    if (apiTypeB && apiTypeD) {
      const f = fields;
      f.binancePublic = "";
      f.binanceSecret = "";
      f.deribitPublic = "";
      f.deribitSecret = "";
      setFields(f);
      setOldFields(f);
    } else if (apiTypeB) {
      const f = fields;
      f.binancePublic = "";
      f.binanceSecret = "";
      f.deribitFuturePublic = "";
      f.deribitFutureSecret = "";
      f.deribitOptionPublic = "";
      f.deribitOptionSecret = "";
      setFields(f);
      setOldFields(f);
    } else if (apiTypeD) {
      const f = fields;
      f.deribitPublic = "";
      f.deribitSecret = "";
      f.binanceFuturePublic = "";
      f.binanceFutureSecret = "";
      f.binanceSpotPublic = "";
      f.binanceSpotSecret = "";
      setFields(f);
      setOldFields(f);
    } else {
      const f = fields;
      f.binanceFuturePublic = "";
      f.binanceFutureSecret = "";
      f.binanceSpotPublic = "";
      f.binanceSpotSecret = "";
      f.deribitFuturePublic = "";
      f.deribitFutureSecret = "";
      f.deribitOptionPublic = "";
      f.deribitOptionSecret = "";
      setFields(f);
      setOldFields(f);
    }
  };

  const saveKeysToAuthAndLS = async (objToSave: any) => {
    const publicObj: any = {};
    const secretObj: any = {};
    let isAvailableSecret = false;

    for (const item in objToSave) {
      if (!item?.toLowerCase().includes("secret")) {
        publicObj[item] = objToSave[item];
      } else {
        secretObj[item] = objToSave[item];
      }
    }

    const checkAvailableSecret = (objToCheck: any) => {
      for (const item in objToCheck) {
        if (typeof secretObj[item] === "object") {
          checkAvailableSecret(secretObj[item]);
        } else {
          if (item?.toLowerCase().includes("secret") && secretObj[item]) {
            isAvailableSecret = true;
          }
        }
      }
    };

    checkAvailableSecret(secretObj);

    const parsedLSAcc = await fetchAccounts();
    const filter = parsedLSAcc.filter(
      (item: any) => item.username === loggedinUser?.sub
    );

    if (filter.length) {
      const newArr: any = [];

      for (const item of parsedLSAcc) {
        if (item.username === loggedinUser?.sub) {
          newArr.push({
            ...secretObj,
            username: loggedinUser?.sub,
            secretAvailable: isAvailableSecret,
          });
        } else {
          newArr.push(item);
        }
      }

      await inputAccounts(newArr);
    } else {
      const newArr = [
        ...parsedLSAcc,
        {
          ...secretObj,
          username: loggedinUser?.sub,
          secretAvailable: isAvailableSecret,
        },
      ];
      await inputAccounts(newArr);
    }

    try {
      const res: any = await axios.post(
        "/api/auth/saveKeys",
        {
          userID: loggedinUser?.sub,
          value: { ...publicObj, secretAvailable: isAvailableSecret },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoggedinUser({
        ...loggedinUser,
        metadata: res.data,
        email_verified: loggedinUser?.email_verified ?? false,
        email: loggedinUser?.email ?? "",
        name: loggedinUser?.name ?? "",
        sub: loggedinUser?.sub ?? "",
        nickname: loggedinUser?.nickname ?? "",
        picture: loggedinUser?.picture ?? "",
        updated_at: loggedinUser?.updated_at ?? "",
      });
      toast.success("Successfully saved your settings");
    } catch (error: any) {
      toast.error(
        "Error saving object: " + error.response?.data || error.message
      );
      console.error(
        "Error saving object:",
        error.response?.data || error.message
      );
    }
  };

  const reloadCCXT = async (data: any, type?: string) => {
    let parseStorage;

    if (data) {
      parseStorage = data;
    } else {
      parseStorage = dataKeys ? dataKeys : {};
    }

    if (parseStorage) {
      await inputLoadedSettings(true);
    }

    const apiTypeBinance = type === "save" ? apiTypeB : parseStorage.apiTypeB;
    const apiTypeDeribit = type === "save" ? apiTypeD : parseStorage.apiTypeD;

    if (apiTypeBinance && apiTypeDeribit) {
      await resetCCXTNow(parseStorage, apiTypeBinance, apiTypeDeribit);
    } else if (apiTypeBinance && !apiTypeDeribit) {
      if (parseStorage.deribitPublic && parseStorage.deribitSecret) {
        await resetCCXTNow(parseStorage, apiTypeBinance, apiTypeDeribit);
      }
    } else if (apiTypeDeribit && !apiTypeBinance) {
      if (parseStorage.binancePublic && parseStorage.binanceSecret) {
        await resetCCXTNow(parseStorage, apiTypeBinance, apiTypeDeribit);
      }
    } else {
      await resetCCXTNow(parseStorage, apiTypeBinance, apiTypeDeribit);
    }
  };

  const resetCCXTNow = async (
    parseStorage: any,
    apiTypeBinance: boolean,
    apiTypeDeribit: boolean,
    type?: string
  ) => {
    if (type === "initial") {
      await resetCCXT(
        "",
        "",
        "",
        parseStorage.derPublic,
        parseStorage.derSecret,
        false,
        false,
        apiTypeBinance,
        apiTypeDeribit,
        null,
        null,
        null,
        null
      );
      setMockAPI(true);
    } else {
      const decryptedKeys = decryptKeys(parseStorage);

      await resetCCXT(
        parseStorage.proxy,
        decryptedKeys.binancePublic,
        decryptedKeys.binanceSecret,
        decryptedKeys.deribitPublic,
        decryptedKeys.deribitSecret,
        parseStorage.isTestKeysBinance,
        parseStorage.isTestKeysDeribit,
        apiTypeBinance,
        apiTypeDeribit,
        decryptedKeys.binancePublic_specific,
        decryptedKeys.binanceSecret_specific,
        decryptedKeys.deribitPublic_specific,
        decryptedKeys.deribitSecret_specific
      );
    }
  };

  const handleReset = async (type: string) => {
    const data = dataKeys;
    setFields(oldFields);
    setIsTestKeysBinance(data?.isTestKeysBinance);
    setIsTestKeysDeribit(data?.isTestKeysDeribit);
    if (type !== "specific") {
      setAPITypeB(data?.apiTypeB);
      setAPITypeD(data?.apiTypeD);
    }
  };

  const handleSet = async (apiKeys: any) => {
    if (!apiKeys) return;

    const { lockscreen, ...restData } = apiKeys;

    const time = lockscreen?.time
      ? +lockscreen.time
      : +process.env.NEXT_PUBLIC_LOCKSCREEN!;
    const pwd = lockscreen?.pwdToggle || false;

    const updatedLockscreen = {
      ...lockscreen,
      pwdToggle: pwd,
    };

    const data = { ...restData, lockscreen: updatedLockscreen };

    const decryptedKeys = decryptKeys(data);
    const newData: any = {
      binancePublic: decryptedKeys.binancePublic,
      deribitSecret: decryptedKeys.deribitSecret,
      binanceSecret: decryptedKeys.binanceSecret,
      deribitPublic: decryptedKeys.deribitPublic,
      ...decryptedKeys.binanceSecret_specific,
      ...decryptedKeys.binancePublic_specific,
      ...decryptedKeys.deribitSecret_specific,
      ...decryptedKeys.deribitPublic_specific,
      apiTypeB: data.apiTypeB,
      apiTypeD: data.apiTypeD,
      proxy: data.proxy,
      lockTime: time,
      lockPwdToggle: updatedLockscreen.pwdToggle,
    };

    setIsTestKeysBinance(data.isTestKeysBinance);
    setIsTestKeysDeribit(data.isTestKeysDeribit);
    setAPITypeB(data.apiTypeB);
    setAPITypeD(data.apiTypeD);
    setDataKeys(data);
    setFields(newData);
    setOldFields(newData);
    setDarkMode(data.darkMode);
    setLockTime(time);
    setLockPwd(data.lockPwdToggle);

    await reloadCCXT(data);
  };

  const handleCheckDeribit = async (e: any, b: string) => {
    if (b !== "warning") {
      setDeribitCheckState(true);
    }

    const db: any = await checkDeribitKey(
      e.public,
      e.secret,
      e.type,
      fields.proxy,
      isTestKeysDeribit
    );

    if (b !== "warning") {
      if (db.error) {
        toast.error(db.error);
      } else {
        toast.success("Deribit: Valid API Key and Secret Key.");
      }
      setDeribitCheckState(false);
    } else {
      if (db.error) {
        return "Your Deribit API/Secret keys are invalid.";
      } else {
        return "valid";
      }
    }
  };

  const handleCheckBinance = async (e: any, b: string) => {
    if (b !== "warning") {
      setBinanceCheckState(true);
    }

    const bn: any = await checkBinanceKey(
      e.public,
      e.secret,
      e.type,
      fields.proxy,
      isTestKeysBinance
    );

    if (b !== "warning") {
      if (bn.error) {
        toast.error(bn.error);
      } else {
        toast.success("Binance: Valid API Key and Secret Key.");
      }
      setBinanceCheckState(false);
    } else {
      if (bn.error) {
        return "Your Binance API/Secret keys are invalid.";
      } else {
        return "valid";
      }
    }
  };

  const handleLoadLS = () => {
    setOpenPwd(true);
  };

  const checkAccounts = async () => {
    if (!isLogin) {
      return;
    }
    const userKeys = loggedinUser?.metadata?.user_metadata || {};

    if (
      Object.keys(userKeys).length > 0 &&
      !count &&
      loggedinUser?.metadata?.user_metadata?.password
    ) {
      toast.info(<ConfirmLoad load={() => handleLoadLS()} />, {
        autoClose: 10000,
        closeOnClick: true,
      });
      count++;
    }
    await inputLoadedSettings(false);
  };

  const handleTestNetBinance = (e: boolean) => {
    setIsTestKeysBinance(e);
  };

  const handleTestNetDeribit = (e: boolean) => {
    setIsTestKeysDeribit(e);
  };

  const handleDeleteUser = async () => {
    const parsedStorage = await fetchAccounts();
    const id = +currentUser;
    const filter = parsedStorage.filter((item: any) => +item.id !== id);

    await inputAccounts(filter);
    toast.success("Successfully deleted current user.");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleLockNow = () => {
    console.log("Should lock now");
    setLockTime(10);
  };

  return (
    <SettingsContext.Provider
      value={{
        isTestKeysBinance,
        isTestKeysDeribit,
        fields,
        openModal,
        dataKeys,
        openPwd,
        typeLoad,
        saveAlert,
        apiTypeB,
        apiTypeD,
        currentUser,
        lockTime,
        binanceCheckState,
        deribitCheckState,
        lockPwdToggle,
        mockAPI,
        handleResetEncryption,
        setMockAPI,
        handleLockNow,
        setLockPwd,
        setLockTime,
        setCurrentUser,
        setAPITypeB,
        setAPITypeD,
        setSaveAlert,
        setTypeLoad,
        setOpenPwd,
        setModal,
        handleField,
        handleReset,
        handleSave,
        handleSet,
        handleCheckDeribit,
        handleCheckBinance,
        handleTestNetBinance,
        handleTestNetDeribit,
        handleDeleteUser,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
